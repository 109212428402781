
.bottom{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.middle{
  height: 40%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

}
.left {
  width: 50%;
  transition: 0.5s;
  opacity: 0.2;
  transform: scale(0.9, 1);
}
.left:hover {
  background-color: #aaccdd;
  transition: 0.5s;
  opacity: 1;
  transform: scale(1, 1);
  z-index: 100;
}
.text {
  font-family: Arial, Helvetica, sans-serif;
}

.right {
  width: 50%;
  transition: 0.5s;
  opacity: 0.2;
  transform: scale(0.9, 1);
}
.right:hover {
  background-color: #4d7cc7;
  width: 50%;
  opacity: 1;
  transform: scale(1, 1);
  z-index: 100;
}
.topLeft {
  height: 10%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  color: #1879ce;
}
.topRight {
  height: 10%;
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  padding-left: 10px;
  color: #d2eee9;
}
.leftButton{
  color: #1879ce;
}
.leftButton::before,
.leftButton::after{
  background: #1879ce;
}
.leftSpanClass::before,
.leftSpanClass::after{
  background: #1879ce;
}
.leftButton:hover::before,
.leftButton:hover::after {
  width: 100%;
}
.leftButton:hover .leftSpanClass::before,
.leftButton:hover .leftSpanClass::after {
  height: 100%;
}
.rightButton{
  color: #d2eee9;
}
.rightButton::before,
.rightButton::after{
  background: #d2eee9;
}
.rightSpanClass::before,
.rightSpanClass::after{
  background: #d2eee9;
}
.rightButton:hover::before,
.rightButton:hover::after {
  width: 100%;
  border-radius: 20px;
}
.rightButton:hover .rightSpanClass::before,
.rightButton:hover .rightSpanClass::after {
  height: 100%;
  border-radius: 20px;
}
.contentL{
  color: #638bca;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  letter-spacing: 8px;
  text-align: center;
}
.contentR{
  color: #d8e0ef;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  letter-spacing: 8px;
  text-align: center;
}
.bottom2{

  background-image: url("../../../assets/profilePhoto.png");
}

@media (max-width: 1440px) {
  .contentL{

    font-size: 36px;
  }
  .contentR{
    font-size: 36px;
  }
}
@media (max-width: 680px) {
  .contentL{
    font-size: 24px;
    letter-spacing: 6px;
  }
  .contentR{
    font-size: 24px;
    letter-spacing: 6px;
  }
  .middle{
    height: 30%;
  }
}
@media (max-width: 440px) {
  
}