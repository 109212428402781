.skills {
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px dashed #5f8dbf;
  padding: 10px;
  padding-left: 0px;
  border-radius: 10px;
  transition: 1s;
}
.skillSpan {
  font-size: 12px;
  font-weight: bold;
}
.skillsUl {
  padding-left: 20px;
}
.skillLi {
  font-size: 12px;
}
.title {
    padding-left: 10px;
    color: #416666;
  }

.skills:hover {
  background-color: #cae2c9;
  color: #496571;
}

.skillsPopup {
  width: 400px;
  height: 850px;
  background-color: #cae2c9;
  padding: 20px;
  color: #496571;
  box-shadow: rgba(119, 155, 144, 0.5) 0px 3px 18px;
}

.skillsPopupUl {
  padding: 0px;
}

.trapezoidSkills {
  border-bottom: 130px solid transparent;
  border-top: 590px solid transparent;
  border-right: 250px solid #496571;
  height: 850px;
  opacity: 0.4;
}

.skillsPopupContainer {
  position: absolute;
  bottom: -130px;
}
.closedPopup {
  display: none;
}

.closeButton {
  display: flex;
  width: 400px;
  justify-content: flex-end;
  cursor: pointer;
}
.close {
  position: absolute;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1px solid #496571;
  border-radius: 100px;
  margin-top: 10px;
  margin-right: 10px;
}

/* POPUP */

.popupContainer {
  display: flex;
  align-items: center;
}

.popupTitle {
  font-weight: bold;
  color: #496571;
  margin-bottom: 10px;
}

.br {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #496571;
}
.popupTextLi {
  list-style-type: none;
}
@media (max-width: 1440px) {
  .popupContainer {
    scale: 0.8;
  }
  .skillsPopupContainer {
    left: -65px;
    bottom: -170px;
  }
  .trapezoidSkills {
    border-bottom: 110px solid transparent;
    border-top: 585px solid transparent;
    border-right: 250px solid #496571;
    height: 850px;
    opacity: 0.4;
  }
}
@media (max-width: 1045px) {
  .popupContainer {
    scale: 0.8;
  }
  .trapezoidSkills {
    display: none;
  }

  .skillsPopup {
    width: 621px;
    height: 772px;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .skillsPopupContainer {
    left: -371px;
    bottom: -155px;
  }
}
