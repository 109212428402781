
.container{
    background-color: #aaccdd;
    height: 100%;
    padding-bottom: 5%;
    width: 100vw;
}
.topSide{
    width: 100vw;
    height: 75px;
    background-color: #d8d9e0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.link{
    text-decoration: none;
    color: #1879ce;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 25px;
}
.mainArea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.warning{
    margin-top: 15px;
    color: #779b90;
    width: 500px;
}
.learnMore{
    margin-top: 25px;
    color: #638bca;
    opacity: 0.8;
}
@media (max-width: 1040px) {
    .learnMore{
        font-size: 24px;
    }
}
@media (max-width: 640px) {
    .warning{
        width: 80%;
        text-align: center;
    }
    .learnMore{
        width: 80%;
        text-align: center;
    }

}
