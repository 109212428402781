* {
  font-family: Arial, Helvetica, sans-serif;
}
.container {
  background-color: #d8d9e0;
  border: 1px solid #779b90;
  width: 500px;
  height: 775px;
  margin-top: 5%;
  border-radius: 10px;
  color: rgb(119, 155, 144);
  box-shadow: rgba(119, 155, 144, 0.5) 0px 3px 18px;
}
.top {
  height: 20%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #779b90;
}
.topRight {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 15px;
}
.pp {
  width: 125px;
  height: 125px;
  border-radius: 100%;
}
.topLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;
  margin-left: 15px;
}
.name {
  font-size: 36px;
  padding-bottom: 15px;
  color: #416666;
}
.job {
  color: #416666;
}
.bottom {
  height: 70%;
  display: flex;
  justify-content: space-between;
}

.bottomLeft {
  width: 56%;
  margin-left: 15px;
}

.bottomRight {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
}

.educationUl {
  padding-left: 0px;
  list-style-type: none;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.li {
  display: flex;
  align-items: center;
}
.educationLi {
  display: flex;
  flex-direction: column;
}


.educationSpan {
  font-weight: 100;
  font-size: 12px;
}
.education {
  padding-bottom: 15px;
}
.languageArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}
.languageSpan {
  font-size: 12px;
  font-weight: bold;
}

.experienceContainer:hover {
  background-color: #cae2c9;
  color: #496571;
}
.app {
  text-align: center;
  max-width: 300px;
  margin: 20px auto;
}

.label {
  color: #496571;
}

.br {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #496571;
}

@media (max-width: 1440px) {
  .container{
    scale: 0.9;
  }
}
@media (max-width: 485px) {
  .container{
    scale: 0.7;
  }
  
}
@media (max-width: 360px) {
  .container{
    scale: 0.6;
  }
}