.closedPopup {
  display: none;
}

.closeButton {
  display: flex;
  width: 400px;
  justify-content: flex-end;
  cursor: pointer;
}
.close {
  position: absolute;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1px solid #496571;
  border-radius: 100px;
  margin-top: 10px;
  margin-right: 10px;
}

.title {
  margin-bottom: 10px;
  color: #416666;
}

.experienceContainer {
  margin-top: 10px;
  border: 1px dashed #5f8dbf;
  padding: 5px;
  border-radius: 10px;
  transition: 1s;
}

.leftSideUlExperience {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  height: 110px;
  margin-top: 10px;
}
.experience {
  position: relative;
  margin-top: 15px;
}
.experienceContainer:hover {
  background-color: #cae2c9;
  color: #496571;
}

.sixHivePopupContainer {
  position: absolute;
  top: 20px;
}
.innovaPopupContainer {
  position: absolute;
}
.company {
  color: #0c6e67;
  font-size: 12px;
}
.experienceDate {
  margin-top: 5px;
  font-size: 10px;
}

/* POPUP */

.popupContainer {
  display: flex;
  align-items: center;
}
.popupTitle {
  font-weight: bold;
  color: #496571;
  margin-bottom: 10px;
}
.popupText {
  font-weight: 400;
  list-style-type: none;
}
.leftSideLi {
  font-size: 12px;
}

.sixHivePopup {
  width: 279px;
  height: 176px;
  background-color: #cae2c9;
  padding: 10px;
  border-radius: 10px;
  color: #496571;
  box-shadow: rgba(119, 155, 144, 0.5) 0px 3px 18px;
}

/* .trapezoid6Hive {
    border-bottom: 0px solid transparent;
    border-top: 55px solid transparent;
    border-left: 300px solid #496571;
    height: 220px;
    opacity: 0.4;
    margin-bottom: 5vh;
  } */

.innovaPopup {
  width: 279px;
  height: 181px;
  background-color: #cae2c9;
  border-radius: 10px;
  padding: 10px;
  color: #496571;
  box-shadow: rgba(119, 155, 144, 0.5) 0px 3px 18px;
}

/* 
.trapezoidInnova { 
    border-bottom: 45px solid transparent;
    border-top: 2px solid transparent;
    border-left: 300px solid #496571;
    height: 225px;
    opacity: 0.4;

  } */
