.aboutMeContainer {
  display: flex;
  position: relative;
}

.aboutMePopupContainer {
  position: absolute;
  right: 0px;
  top: -86px;
}

.closedPopup {
  display: none;
}

.closeButton {
  display: flex;
  width: 400px;
  justify-content: flex-end;
  cursor: pointer;
}
.close {
  position: absolute;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1px solid #496571;
  border-radius: 100px;
  margin-top: 10px;
  margin-right: 10px;
}

.aboutMe {
  margin-top: 15px;
  border: 1px dashed #5f8dbf;
  padding: 10px;
  border-radius: 10px;
  transition: 1s;
}

.title {
  margin-bottom: 10px;
  color: #416666;
}

.leftSideUlAboutme {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  height: 150px;
}

.leftSideLi {
  font-size: 12px;
}

.aboutMe:hover {
  background-color: #cae2c9;
  color: #496571;
}
/* POPUP */

.popupContainer {
  display: flex;
  align-items: center;
}

.aboutMePopup {
  width: 400px;
  height: 430px;
  background-color: #cae2c9;
  padding: 20px;
  color: #496571;
  box-shadow: rgba(119, 155, 144, 0.5) 0px 3px 18px;
}

.popupTitle {
  font-weight: bold;
  color: #496571;
  margin-bottom: 10px;
}
.popupText {
  font-weight: 400;
  list-style-type: none;
}
.popupTextLi {
  font-weight: 400;
  list-style-type: none;
}
.trapezoidAboutMe {
  border-bottom: 135px solid transparent;
  border-top: 105px solid transparent;
  border-left: 300px solid #496571;
  height: 430px;
  opacity: 0.4;
  z-index: 100;
}
@media (max-width: 1440px) {
  .popupContainer{
    scale: 0.8;
  }
  .trapezoidAboutMe {
    border-left: 378px solid #496571;
    border-bottom: 115px solid transparent;
    border-top: 75px solid transparent;
  }
  .aboutMePopupContainer {
    position: absolute;
    right: -78px;
    top: -86px;
  }
}
@media (max-width: 1045px) {
  .popupContainer{
    scale: 1;
  }
  .trapezoidAboutMe{
    display: none;
  }
  .aboutMePopupContainer {
    z-index: 1000;
    right: -204px;
    top: 0px;
    
  }
  .aboutMePopup {
    width: 498px;
    height: 618px;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}