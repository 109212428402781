* {
  margin: 0px;
}

.container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  transition-duration: 0.1s
}

.line {
  border: 0.5px solid black;
}
@media (min-width: 2200px) {
  
}