


.personalDetails {
    position: relative;
    border: 1px dashed #5f8dbf;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    transition: 1s;
  }

  .personalDetailsUl {
    padding-left: 0px;
    list-style-type: none;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .closedPopup {
    display: none;
  }
  
  .closeButton {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .close {
    position: absolute;
    background-color: transparent;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: 1px solid #496571;
    border-radius: 100px;
  }

  .title {
    margin-bottom: 10px;
    color: #416666;
  }

  .li {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  .linkedIn {
    width: 130px;
    word-wrap: break-word;
  }
  .personalDetailsPopupContainer {
    position: absolute;
    top: -18px;
    right: -16px;
  }
  
/* POPUP */

.popupContainer {
    display: flex;
    align-items: center;
  }
.popupTitle {
    font-weight: bold;
    color: #496571;
    margin-bottom: 10px;
  }
  .popupText {
    font-weight: 400;
    list-style-type: none;
  }
  .leftSideLi {
    font-size: 12px;
  }

  .trapezoidPersonalDetails {
    border-bottom: 170px solid transparent;
    border-top: 110px solid transparent;
    border-right: 250px solid #496571;
    height: 420px;
    opacity: 0.4;
  }
  .formMessage {
    color: #496571;
    padding-bottom: 15px;
  }

  .formArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 15px;
  }
  .formInput {
    width: 200px;
    height: 30px;
    border: none;
    border-radius: 10px;
    padding-left: 15px;
  }
  .formInput::placeholder {
    color: #496571;
  }


.textarea {
    min-height: 100px;
    min-width: 200px;
    max-height: 300px;
    max-width: 200px;
    padding-left: 15px;
    border: none;
    border-radius: 10px;
    padding-top: 5px;
  }
  textarea::placeholder {
    color: #496571;
  }

  .submitButton {
    border: none;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    background-color: #496571;
    color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .detailsTitle {
    font-weight: bold;
    color: #496571;
    padding-top: 5px;
    border-top: 1px dashed #5f8dbf;
  }
  
  .linkedinLink {
    text-decoration: none;
    color: #5f8dbf;
    font-weight: 400;
  }
  .personalDetailsPopup {
    width: 498px;
    height: 620px;
    background-color: #cae2c9;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 100;
  }
  .personalDetails:hover {
    background-color: #cae2c9;
    color: #496571;
  }
  