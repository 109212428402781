.container {
    display: flex;
    flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/bob.jpeg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #4d7cc7;
}
.mainText{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 48px;
    color: #d8e0ef;
}
.link{
    text-decoration: none;
    color: #dbe2e5;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 50px;
    margin-top: 25px;
}
@media (min-width: 3500px) {
    .mainText{
        justify-content: flex-end;
        margin-right: 5%;
        font-size: 62px;
    }
    .link{
        font-size: 48px;
    }
}
@media (max-width: 1880px) {
    .mainText{
        font-size: 36px;
    }
}
@media (max-width: 1440px) {
    .mainText{
        font-size: 24px;
    }
}
@media (max-width: 940px) {
    .mainText{
        font-size: 20px;
    }
    .link{
        margin-left: 15px;
    margin-top: 15px;
    font-size: 14px;
    }
}
@media (max-width: 450px) {
    .mainText{
        font-size: 20px;
        margin-top: 50px;
    }
    .link{
        margin-left: 15px;
    margin-top: 15px;
    font-size: 10px;
    }
}
/* @media (max-width: 1880px) {
    .mainText{
        font-size: 36px;
    }
} */