*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.button {
  width: 194px;
  height: 81px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  position: relative;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin: 20px 30px;
  background: transparent;
  font-family: Arial, Helvetica, sans-serif;
}

.button::before,
.button::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.5s linear;
}

.span {
  display: block;
}

.span::before,
.span::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.5s linear;
}


.button:hover::before,
.button:hover::after {
  width: 100%;
}
.button:hover .span::before,
.button:hover .span::after {
  height: 100%;
}
.button::after {
  left: 0;
  bottom: 0;
  transition-delay: 0.6s;
}
.button .span::after {
  transition-delay: 0.4s;
  right: 0;
  bottom: 0;
}
.button::before {
  right: 0;
  top: 0;
  transition-delay: 0.2s;
}
.button .span::before {
  transition-delay: 0s;
  left: 0;
  top: 0;
}

.button:hover::after {
  transition-delay: 0s;
}
.button:hover .span::after {
  transition-delay: 0.2s;
}
.button:hover::before {
  transition-delay: 0.4s;
}
.button:hover .span::before {
  transition-delay: 0.6s;
}
.buttonContainer{
  background-color: transparent;
  border: none;
}
@media (max-width: 440px) {
  .button{
    width: 100px;
    height: 60px;
  }
}